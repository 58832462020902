import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Lottie from "react-lottie"
import AlfieAmazed from "../assets/animation/Dog_Sitting-Talking_Amazed"
import BreadCrumbs from "../components/bread-crumbs"
import "./privacy-policy.scss"

const breadCrumbsList = [
  {
    title: "Home",
    link: "/",
  },
]

class TermsAndConditions extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.analytics.page("Terms and Conditions")
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Terms And Conditions" />
        <BreadCrumbs
          currentPage={"Terms And Conditions"}
          breadCrumbsList={breadCrumbsList}
        />
        <div className="poli-wave-container">
          <div className="poli-content-wrap">
            <div className="privacy-header">
              <h2 className="blackHeading">Terms And Conditions</h2>
            </div>
            <div className="manifesto-animation">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: AlfieAmazed,
                }}
                height={430}
                width={460}
              />
            </div>
            <div className="privacy-list">
              <div className="single-privacy">
                <div className="festo-content">
                  <h2 className="festo-head">1. Terms</h2>
                  <p className="festo-parah">
                    By accessing the confidant website or utilizing its mobile
                    app, you are agreeing to be bound by these terms of service,
                    all applicable laws and regulations, and agree that you are
                    responsible for compliance with any applicable local laws.
                    If you do not agree with any of these terms, you are
                    prohibited from using or accessing this site. The materials
                    contained in this website are protected by applicable
                    copyright and trademark law.
                  </p>
                  <h2 className="festo-head">2. Use License</h2>
                  <p className="festo-parah">
                    Permission is granted to temporarily download one copy of
                    the materials (information or software) on Confidant
                    Health's website for personal, non-commercial transitory
                    viewing only. This is the grant of a license, not a transfer
                    of title, and under this license you may not:
                  </p>
                  <ul className="policy-ul">
                    <li>Modify or copy the materials;</li>
                    <li>
                      Use the materials for any commercial purpose, or for any
                      public display (commercial or non-commercial);
                    </li>
                    <li>
                      Attempt to decompile or reverse engineer any software
                      contained on Confidant Health's website;
                    </li>
                    <li>
                      Remove any copyright or other proprietary notations from
                      the materials;
                    </li>
                    <li>
                      Transfer the materials to another person or "mirror" the
                      materials on any other server.
                    </li>
                  </ul>
                  <p className="festo-parah">
                    This license shall automatically terminate if you violate
                    any of these restrictions and may be terminated by Confidant
                    Health at any time. Upon terminating your viewing of these
                    materials or upon the termination of this license, you must
                    destroy any downloaded materials in your possession whether
                    in electronic or printed format.
                  </p>
                  <h2 className="festo-head">3. Disclaimer</h2>
                  <p className="festo-parah">
                    The materials on Confidant Health's website are provided on
                    an 'as is' basis. Confidant Health makes no warranties,
                    expressed or implied, and hereby disclaims and negates all
                    other warranties including, without limitation, implied
                    warranties or conditions of merchantability, fitness for a
                    particular purpose, or non-infringement of intellectual
                    property or other violation of rights.
                    <br />
                    <br />
                    Further, Confidant Health does not warrant or make any
                    representations concerning the accuracy, likely results, or
                    reliability of the use of the materials on its website or
                    otherwise relating to such materials or on any sites linked
                    to this site.
                  </p>
                  <h2 className="festo-head">4. Limitations</h2>
                  <p className="festo-parah">
                    In no event shall Confidant Health or its suppliers be
                    liable for any damages (including, without limitation,
                    damages for loss of data or profit, or due to business
                    interruption) arising out of the use or inability to use the
                    materials on Confidant Health's website, even if Confidant
                    Health or a Confidant Health authorized representative has
                    been notified orally or in writing of the possibility of
                    such damage. Because some jurisdictions do not allow
                    limitations on implied warranties, or limitations of
                    liability for consequential or incidental damages, these
                    limitations may not apply to you.
                  </p>

                  <h2 className="festo-head">5. Accuracy of materials</h2>
                  <p className="festo-parah">
                    The materials appearing on Confidant Health's website could
                    include technical, typographical, or photographic errors.
                    Confidant Health does not warrant that any of the materials
                    on its website are accurate, complete or current. Confidant
                    Health may make changes to the materials contained on its
                    website at any time without notice. However Confidant Health
                    does not make any commitment to update the materials.
                  </p>
                  <h2 className="festo-head">6. Links</h2>
                  <p className="festo-parah">
                    Confidant Health has not reviewed all of the sites linked to
                    its website and is not responsible for the contents of any
                    such linked site. The inclusion of any link does not imply
                    endorsement by Confidant Health of the site. Use of any such
                    linked website is at the user's own risk.
                  </p>
                  <h2 className="festo-head">7. Modifications</h2>
                  <p className="festo-parah">
                    Confidant Health may revise these terms of service for its
                    website at any time without notice. By using this website
                    you are agreeing to be bound by the then current version of
                    these terms of service.
                  </p>
                  <h2 className="festo-head">8. Governing Law</h2>
                  <p className="festo-parah">
                    These terms and conditions are governed by and construed in
                    accordance with the laws of Texas and you irrevocably submit
                    to the exclusive jurisdiction of the courts in that State,
                    and within Travis County.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default TermsAndConditions
